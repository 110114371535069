define("discourse/plugins/waze-markdown/discourse-markdown/waze-markdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = void 0;
  var t = {
      d: (n, e) => {
        for (var r in e) t.o(e, r) && !t.o(n, r) && Object.defineProperty(n, r, {
          enumerable: !0,
          get: e[r]
        });
      },
      o: (t, n) => Object.prototype.hasOwnProperty.call(t, n)
    },
    n = {};
  t.d(n, {
    m: () => f
  });
  const e = "\n  color: #202124;\n  background-color: #ffffff;\n  border-radius: 12px;\n  max-width: 640px;\n  margin: 15px auto 15px auto;\n  padding: 10px;",
    r = {
      wzboxes: "\n  background-color: #efefea;\n  margin: 0;\n  padding: 10px;\n  width: 100%;\n  box-sizing: border-box;".trim(),
      wzbox: `\n  ${e}`.trim(),
      wzboxblue: `\n  ${e}\n  background-color: #33ccff;\n  `.trim(),
      wzboxgreen: `\n  ${e}\n  background-color: #52eeaa;\n  `.trim(),
      wzboxred: `\n  ${e}\n  background-color: #ff8f8f;\n  `.trim()
    },
    o = "\nfont-family: var(--heading-font-family, --wz-font-family);\nfont-weight: var(--wz-font-weight, 500);\n",
    a = {
      wzh1: `\n  ${o}\n  font-size: 36px;`.trim(),
      wzh2: `\n  ${o}\n  font-size: 28px;`.trim(),
      wzh3: `\n  ${o}\n  font-size: 24px;`.trim(),
      wzh4: `\n  ${o}\n  font-size: 20px;\n  letter-spacing: var(--wz-letter-spacing, 0.15px);`.trim(),
      wzh5: `\n  ${o}\n  font-size: 18px;\n  letter-spacing: var(--wz-letter-spacing, 0.15px);`.trim(),
      wzh6: `\n  ${o}\n  font-size: 16px;\n  letter-spacing: var(--wz-letter-spacing, 0.15px);`.trim(),
      wzh7: `\n  ${o}\n  font-size: 14px;\n  letter-spacing: var(--wz-letter-spacing, 0.2px);`.trim()
    },
    i = Object.assign(Object.assign({}, r), a),
    s = Object.values(i);
  function c(t, n, e) {
    return function (r, o, a) {
      r.tag = o.tag = t, r.content = o.content = "", r.type = "bbcode_open", o.type = "bbcode_close", r.nesting = 1, o.nesting = -1, r.attrs = [[n, e ? e(a) : a.attrs._default]];
    };
  }
  function l(t) {
    const n = t.inline.bbcode.ruler;
    n.push("wzh", {
      tag: "wzh",
      wrap: c("span", "style", t => {
        var n;
        return null !== (n = i[`wzh${t.attrs._default.trim()}`]) && void 0 !== n ? n : "";
      })
    }), n.push("size", {
      tag: "size",
      wrap: c("span", "style", t => `font-size:${t.attrs._default.trim()}%`)
    }), n.push("color", {
      tag: "color",
      wrap: c("span", "style", t => `color:${t.attrs._default.trim()}`)
    }), ["left", "right", "center"].forEach(n => {
      t.block.bbcode.ruler.push(n, {
        tag: n,
        wrap: function (t) {
          return t.attrs = [["style", `text-align:${n}`]], !0;
        }
      });
    });
    for (const [n, e] of Object.entries(i)) t.block.bbcode.ruler.push(n, {
      tag: n,
      wrap: t => (t.attrGet("style") ? t.attrJoin("style", `;${e}`) : t.attrPush(["style", e]), !0)
    });
  }
  function f(t) {
    t.allowList({
      custom: (t, n, e) => !("style" !== n || !s.includes(e)) || !("div" !== t || "style" !== n || !/^text-align:(center|left|right)$/.exec(e)) || !("span" !== t || "style" !== n || !/^(font-size:([0-9]{1,3}%)|color:#?[a-zA-Z0-9]+)$/.exec(e)) || void 0
    }), t.registerOptions(t => {
      t.features.bbcode = !0;
      for (const n of Object.keys(i)) t.features[n] = !0;
    }), t.registerPlugin(l);
  }
  var p = _exports.setup = n.m;
});